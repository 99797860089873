import React from 'react'

const CartIcon = () => (
  <svg width={26} height={26} viewBox="0 0 26 26" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.87 12.963a2.488 2.488 0 01-2.187 1.287H9.37l-1.375 2.5h15v2.5h-15c-1.9 0-3.1-2.038-2.187-3.713l1.687-3.05L2.995 3h-2.5V.5h4.088L5.758 3h18.5c.95 0 1.55 1.025 1.087 1.85l-4.475 8.113zM22.133 5.5H6.945l2.963 6.25h8.775l3.45-6.25zm-14.138 15A2.497 2.497 0 005.508 23c0 1.375 1.112 2.5 2.487 2.5s2.5-1.125 2.5-2.5-1.125-2.5-2.5-2.5zM18.008 23c0-1.375 1.112-2.5 2.487-2.5s2.5 1.125 2.5 2.5-1.125 2.5-2.5 2.5a2.497 2.497 0 01-2.487-2.5z"
      fill="#242424"
    />
  </svg>
)

export default CartIcon
