import styled from 'styled-components'

import { fonts } from '../../utils'

export const H1 = styled.h1`
  font-size: 5rem;
  font-weight: 700;
  color: ${({ color }) => color};
`

export const H2 = styled.h2`
  font-size: 3.6rem;
  font-weight: 700;
  color: ${({ color }) => color};
`

export const H3 = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ color }) => color};
  @media (min-width: 800px) {
    font-size: 2.5rem;
  }
`

export const ParagraphSmall = styled.p`
  font-size: 1.6rem;
  white-space: pre-line;
  font-weight: ${({ w = 400 }) => w};
  color: ${({ color }) => color};
`

export const ParagraphMedium = styled.p`
  font-size: 1.4rem;
  white-space: pre-line;
  font-weight: ${({ w = 400 }) => w};
  color: ${({ color }) => color};
  @media (min-width: 800px) {
    font-size: 1.8rem;
  }
`

export const ParagraphLarge = styled.p`
  font-size: 2.4rem;
  font-weight: ${({ w = 400 }) => w};
  white-space: pre-line;
  color: ${({ color }) => color};
`

export const Button = styled.p`
  font-family: ${fonts.primary};
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ color }) => color};
  text-transform: uppercase;
  @media (min-width: 800px) {
    font-size: 1.8rem;
  }
`

export const Subtitle = styled.p`
  font-family: ${fonts.primary};
  font-size: 1.8rem;
  font-weight: 400;
  color: ${({ color }) => color};

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

export const CardTitle = styled.p`
  font-family: ${fonts.rubik};
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ color }) => color};
`
