export default {
  primary: '#BE1E2D',
  secondary: '#13253F',
  black: '#242424',
  gray: '#B6B6B6',
  gray2: '#bfbfbf',
  gray3: '#e2e2e2',
  lighter: '#4B4B4B',
  white: '#ffffff',
  lightGray: '#fdfdfd',
}
