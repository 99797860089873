import { createGlobalStyle } from 'styled-components'

import { colors, fonts } from '../../utils'
import './fonts.css'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    height: 100%;

    font-size: 8px;
  }

  :before,
  :after {
    box-sizing: border-box;
  }

  body {
    background: ${colors.white};
    color: ${colors.black};
    font-family: ${fonts.secondary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    min-height: 100%;
display: grid;
grid-template-rows: 1fr auto;
  }

  h1, h2, h3, h4 {
    font-family: ${fonts.primary};
  }


  .notification-success {
    background-color: ${colors.secondary};
  }  

  .notification .title {
    font-size: 1.6rem;
    text-transform: capitalize;
  } 


  @media (min-width: 600px) {
    html {
      font-size: 10px;
    }
  }
`
