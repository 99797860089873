import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby-plugin-intl'

import { colors, fonts } from '../../utils'

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3rem 10%;
  font-size: 1.8rem;
  box-shadow: 0px 4px 10px rgba(36, 36, 36, 0.2);
`
export const Items = styled.ul`
  list-style: none;
  @media (max-width: 800px) {
    display: none;
  }
`
export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
export const Item = styled.li`
  font-family: ${fonts.primary};
  font-size: 1.8rem;
  font-weight: 500;
  color: ${colors.black};
  display: inline-block;
  padding: 0 1.25rem;
  text-decoration: none;
  @media (max-width: 800px) {
    display: block;
    padding: 1.25rem;
    text-align: center;
    border-bottom: 1px solid grey;
  }
`
export const Center = styled.nav`
  margin-right: 1.25rem;
`
export const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Button = styled.button`
  background-color: ${colors.white};
  border: none;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  margin: 0.25rem 0.125rem;
  cursor: pointer;
  display: none;
  outline: none;
  @media (max-width: 800px) {
    display: inline;
    padding: 0rem;
  }
`
export const Select = styled.select`
  background: #ffffff;
  margin-right: 0.625rem;
  padding: 0.7rem 1.2rem;
  border: 0.5px solid rgba(36, 36, 36, 0.6);
  box-sizing: border-box;
  border-radius: 5px;
  @media (max-width: 800px) {
    display: none;
  }
`
export const Option = styled.option`
  font-size: 1.2rem;
  padding: 1.25rem;
`
export const ItemsInMobile = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
`
export const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  color: ${colors.black};
  &:hover {
    border-bottom: 3px solid ${colors.primary};
  }
  &:active {
    border-bottom: 3px solid ${colors.primary};
  }
`
