import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby-plugin-intl'
import { fonts, colors } from '../../utils'

export const Container = styled.div`
  background-color: ${colors.primary};
  color: ${colors.white};
  width: 100%;
  padding: 3rem 2rem 2rem 6rem;
  @media (min-width: 600px) {
    padding: 7rem 0 4rem 0;
  }
`

export const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: wrap;
  }
`
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 3rem;
  }
`
export const Center = styled.div`
  display: flex;
  flex-direction: column;
`
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Title = styled.h3`
  font-family: ${fonts.secondary};
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  @media (min-width: 800px) {
    font-size: 3.6rem;
  }
`

export const NavBar = styled.div`
  width: 50%;
  margin: 10rem auto;
`
export const Items = styled.ul`
  display: none;
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }
`
export const Item = styled.li`
  font-size: 1rem;
  display: inline-block;
  text-decoration: none;
  @media (max-width: 800px) {
    display: block;
    text-align: center;
    border-bottom: 1px solid grey;
  }
`
export const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  color: ${colors.white};
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    border-bottom: 3px solid ${colors.primary};
    color: rgba(255, 255, 255, 0.8);
  }
  &:active {
    border-bottom: 3px solid ${colors.primary};
  }
`
export const Rights = styled.div`
  font-family: ${fonts.secondary};
  font-size: 1.6rem;
  text-align: center;
`
