import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import {
  Link,
  changeLocale,
  useIntl,
  FormattedMessage,
} from 'gatsby-plugin-intl'
import { css } from 'styled-components'

import CartIcon from '../../static/cart.svg'
import { fonts, colors } from '../../utils'

import {
  NavBar,
  Items,
  Logo,
  Item,
  Center,
  RightSide,
  Button,
  Select,
  Option,
  ItemsInMobile,
  StyledLink,
} from './header.style'

const Header = () => {
  const intl = useIntl()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const lang = intl.locale
  return (
    <div>
      <NavBar>
        <Button
          onClick={() => setIsNavOpen(!isNavOpen)}
          css={css`
            font-size: 2rem;
          `}
        >
          ☰
        </Button>
        <Logo>
          <StaticQuery
            query={graphql`
              query {
                file(relativePath: { eq: "logo.png" }) {
                  childImageSharp {
                    resize(width: 30, height: 30) {
                      src
                    }
                  }
                }
              }
            `}
            render={data => (
              <img
                style={{ padding: '0 20px ' }}
                src={data.file.childImageSharp.resize.src}
                alt="logo"
              />
            )}
          />
          <Link
            to="/"
            css={css`
              font-size: 1.8rem;
              color: ${colors.black};
              text-decoration: none;
              font-family: ${fonts.secondary};
            `}
          >
            <FormattedMessage id="title" />
          </Link>
        </Logo>
        <Center>
          <Items>
            <Item>
              <StyledLink to="/">
                <FormattedMessage id="home" />
              </StyledLink>
            </Item>
            <Item>
              <StyledLink to="/contact-us">
                <FormattedMessage id="contact_us" />
              </StyledLink>
            </Item>
            <Item>
              <StyledLink to="/about-us">
                <FormattedMessage id="about_us" />
              </StyledLink>
            </Item>
          </Items>
        </Center>

        <RightSide>
          <Select value={lang} onChange={e => changeLocale(e.target.value)}>
            <Option key="en" value="en">
              English
            </Option>
            <Option key="ar" value="ar">
              العربية
            </Option>
            <Option key="he" value="he">
              עברית
            </Option>
          </Select>
          <Link
            to="/cart"
            css={css`
              margin: ${lang === 'en' ? '0 0 0 4rem' : '0 4rem 0 0'};
            `}
          >
            <CartIcon />
          </Link>
        </RightSide>
      </NavBar>

      <ItemsInMobile style={{ display: isNavOpen ? 'inline' : 'none' }}>
        <Item>
          <StyledLink to="/contact-us">
            <FormattedMessage id="contact_us" />
          </StyledLink>
        </Item>
        <Item>
          <StyledLink to="/about-us">
            <FormattedMessage id="about_us" />
          </StyledLink>
        </Item>
        <Item style={{ display: lang === 'ar' ? 'none' : 'block' }}>
          <StyledLink onClick={() => changeLocale('ar')}>العربية</StyledLink>
        </Item>
        <Item style={{ display: lang === 'en' ? 'none' : 'block' }}>
          <StyledLink onClick={() => changeLocale('en')}>English</StyledLink>
        </Item>
        <Item style={{ display: lang === 'he' ? 'none' : 'block' }}>
          <StyledLink onClick={() => changeLocale('he')}>עברית</StyledLink>
        </Item>
      </ItemsInMobile>
    </div>
  )
}

export default Header
