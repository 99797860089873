import React from 'react'
import { css } from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import BuiltByZaat from './BuiltByZaat'
import { ParagraphSmall, Subtitle } from '../typography'
import facebook from '../../images/facebook.png'
import insta from '../../images/insta.png'
import {
  Container,
  Information,
  Left,
  Center,
  Right,
  Title,
  NavBar,
  Items,
  Item,
  StyledLink,
  Rights,
} from './footer.style'
import { fonts } from '../../utils'

const Footer = () => (
  <Container>
    <Information>
      <Left>
        <Title>
          <FormattedMessage id="title" />
        </Title>
        <ParagraphSmall color="#EFEFEF">
          <FormattedMessage id="footerCotent" />
        </ParagraphSmall>
      </Left>
      <Center>
        <h3
          css={css`
            font-family: ${fonts.secondary};
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 2.5rem;
          `}
        >
          <FormattedMessage id="location" />
        </h3>
        <ParagraphSmall color="#EFEFEF">
          <FormattedMessage id="address" />
        </ParagraphSmall>
      </Center>
      <Right>
        <h3
          css={css`
            font-family: ${fonts.secondary};
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 2.5rem;
          `}
        >
          <FormattedMessage id="social" />
        </h3>
        <div>
          <img
            style={{ marginRight: '0.625rem' }}
            src={facebook}
            alt="facebook icon"
          />
          <img src={insta} alt="insta" />
        </div>
      </Right>
    </Information>
    <NavBar>
      <Items>
        <Item>
          <Subtitle>
            <StyledLink to="/">
              <FormattedMessage id="home" />
            </StyledLink>
          </Subtitle>
        </Item>
        <Item>
          <Subtitle>
            <StyledLink to="/products">
              <FormattedMessage id="products" />
            </StyledLink>
          </Subtitle>
        </Item>
        <Item>
          <Subtitle>
            <StyledLink to="/contact-us">
              <FormattedMessage id="contact_us" />
            </StyledLink>
          </Subtitle>
        </Item>
        <Item>
          <Subtitle>
            <StyledLink to="/about-us">
              <FormattedMessage id="about_us" />
            </StyledLink>
          </Subtitle>
        </Item>
      </Items>
    </NavBar>
    <Rights>
      <FormattedMessage id="rights" />
      {`  ${new Date().getFullYear()}`}
    </Rights>
    <BuiltByZaat />
  </Container>
)

export default Footer
