// categories object to map and create categories card
// name: is for the display name
// path: is for how it will look in the url

export default [
  {
    name: 'regulators',
    path: 'regulators',
  },
  {
    name: 'valves',
    path: 'valves',
  },
  {
    name: 'ovens',
    path: 'ovens',
  },
  {
    name: 'fittings',
    path: 'fittings',
  },
  {
    name: 'cookers',
    path: 'cookers',
  },
  {
    name: 'hoses',
    path: 'hoses',
  },
  {
    name: 'tools',
    path: 'tools',
  },
  {
    name: 'home applicances',
    path: 'home_applicances',
  },
]
