import React from 'react'
import styled from 'styled-components'

const Container = styled.a`
  text-align: center;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: 42vw;
  margin-top: 2vw;
  text-decoration: none;
  color: #333333;
  cursor: pointer;
  width: auto;
  font-weight: bold;
  transition: color 0.3s;
  text-align: center;
  :hover {
    color: #2ab640;
  }
  > span {
    background: -webkit-linear-gradient(#2ab640, #54cf61);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: 1250px) {
    margin-left: 38vw;
  }

  @media (max-width: 900px) {
    margin-left: 31vw;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    margin-left: 20vw;
    margin-top: 4vw;
  }
`

const BuiltByZaat = () => (
  <Container dir="ltr" href="https:\\zaat.dev">
    Built with&nbsp;
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3762 2.54619C18.5386 0.827183 16.1258 -0.579274 13.3191 0.239596C11.9779 0.62699 10.8078 1.45777 9.99986 2.59619C9.19192 1.45777 8.02178 0.62699 6.68062 0.239596C3.86771 -0.566772 1.46111 0.827183 0.623483 2.54619C-0.55169 4.95279 -0.0641182 7.65944 2.0737 10.5911C3.74894 12.8852 6.14304 15.2105 9.61856 17.9109C9.72839 17.9966 9.86369 18.0431 10.003 18.0431C10.1423 18.0431 10.2776 17.9966 10.3874 17.9109C13.8567 15.2168 16.257 12.9102 17.9323 10.5911C20.0638 7.65944 20.5514 4.95279 19.3762 2.54619V2.54619Z"
        fill="#F33232"
      />
    </svg>
    &nbsp;by&nbsp;
    <span>Zaat.dev</span>
  </Container>
)

export default BuiltByZaat
